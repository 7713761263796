const urlConfig = {
    urlFile: 'https://apisiv.hargakurs.com/',
    urlProfile: 'https://apisiv.hargakurs.com/serve/imgprofile/',
    urlId: 'https://apisiv.hargakurs.com/serve/imgid/',
    urlFlag: 'https://apisiv.hargakurs.com/serve/imgflag/',
    urlInvoice: 'https://apisiv.hargakurs.com/bo/report/invoice?invoice='
    // urlFile: 'http://localhost:8090/',
    // urlProfile: 'http://localhost:8090/serve/imgprofile/',
    // urlId: 'http://localhost:8090/serve/imgid/',
    // urlFlag: 'http://localhost:8090/serve/imgflag/',
    // urlInvoice: 'http://localhost:8090/bo/report/invoice?invoice='
}

export default urlConfig;