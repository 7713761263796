import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link, matchPath } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname, authUser, dash } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    let branch = '';
    if (dash && dash.dashadmin) {
      let urlPath = pathname.split("/")
      if (urlPath.length > 1) {
        branch = "/" + urlPath[1];
      }
    }
    return (<Auxiliary>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {(authUser && Object.entries(authUser).length > 0) &&
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              {(authUser.access.parent_dashboard) &&
                <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main" />}>
                  {(authUser.access.dashboard.akses) &&
                    <Menu.Item key="dashboard">
                      <Link to={branch + "/dashboard"}><i className="icon icon-dasbhoard" />
                        <IntlMessages id="sidebar.dashboard" /></Link>
                    </Menu.Item>
                  }

                </MenuItemGroup>
              }

              {authUser.access.parent_transaction &&
                <MenuItemGroup key="transaction" className="gx-menu-group" title={<IntlMessages id="sidebar.transaction" />}>

                  {
                    authUser.access.transaction_buy_add.akses &&
                    <Menu.Item key="transaction/exchangetransbuyadd">
                      <Link to={branch + "/transaction/exchangetransbuyadd"}><i className="icon icon-arrow-right" />
                        <IntlMessages id="sidebar.transaction.exchangetransbuyadd" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_sell_add.akses &&
                    <Menu.Item key="transaction/exchangetransselladd">
                      <Link to={branch + "/transaction/exchangetransselladd"}><i className="icon icon-arrow-left" />
                        <IntlMessages id="sidebar.transaction.exchangetransselladd" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.parent_transaction_list &&
                    <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="transactionlist"
                      title={<span><i className="icon icon-cards-list-view" />
                        <IntlMessages id="sidebar.transaction.list" /></span>}>
                      {
                        authUser.access.transaction_buy_list.akses &&
                        <Menu.Item key="transaction/exchangelist/buy">
                          <Link to={branch + "/transaction/exchangelist/buy"}><i className="icon icon-arrow-right" />
                            <IntlMessages id="sidebar.transaction.exchangetransbuy" />
                          </Link>
                        </Menu.Item>
                      }
                      {
                        authUser.access.transaction_sell_list.akses &&
                        <Menu.Item key="transaction/exchangelist/sell">
                          <Link to={branch + "/transaction/exchangelist/sell"}><i className="icon icon-arrow-left" />
                            <IntlMessages id="sidebar.transaction.exchangetranssell" />
                          </Link>
                        </Menu.Item>
                      }
                    </SubMenu>
                  }

                  {
                    authUser.access.transaction_cash.akses &&
                    <Menu.Item key="transaction/cashier">
                      <Link to={branch + "/transaction/cashier"}><i className="icon icon-editor" />
                        <IntlMessages id="sidebar.transaction.cashier" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.transaction_cash_branch.akses &&
                    <Menu.Item key="transaction/cashier/branch">
                      <Link to={branch + "/transaction/cashier/branch"}><i className="icon icon-editor" />
                        {/* <IntlMessages id="sidebar.transaction.cashier" /> */}
                        Kas Cabang
                        </Link>                        
                    </Menu.Item>
                  }
                  {
                    authUser.access.transaction_transfer_send.akses &&
                    <Menu.Item key="transaction/stocksend">
                      <Link to={branch + "/transaction/stocksend"}><i className="icon icon-chevron-left" />
                        <IntlMessages id="sidebar.transaction.transfer_send" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.transaction_transfer_receive.akses &&
                    <Menu.Item key="transaction/stockreceive">
                      <Link to={branch + "/transaction/stockreceive"}><i className="icon icon-chevron-right" />
                        <IntlMessages id="sidebar.transaction.transfer_receive" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_exchange_rate.akses &&
                    <Menu.Item key="transaction/exchangerate">
                      <Link to={branch + "/transaction/exchangerate"}><i className="icon icon-chart" />
                        <IntlMessages id="sidebar.transaction.exchangerate" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_exchange_frac.akses &&
                    <Menu.Item key="transaction/exchangefrac">
                      <Link to={branch + "/transaction/exchangefrac"}><i className="icon icon-chart" />
                        <IntlMessages id="sidebar.transaction.exchangefrac" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_exchange_rate_bi.akses &&
                    <Menu.Item key="transaction/exchangeratebi">
                      <Link to={branch + "/transaction/exchangeratebi"}><i className="icon icon-chart-area" />
                        <IntlMessages id="sidebar.transaction.exchangeratebi" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_stock_adjustment.akses &&
                    <Menu.Item key="transaction/internaltrans">
                      <Link to={branch + "/transaction/internaltrans"}><i className="icon icon-card" />
                        <IntlMessages id="sidebar.transaction.internaltrans" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_stock_initial.akses &&
                    <Menu.Item key="transaction/internaltransstock">
                      <Link to={branch + "/transaction/internaltransstock"}><i className="icon icon-cards-list-view" />
                        <IntlMessages id="sidebar.transaction.internaltransstock" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_mid_exchange_rate.akses &&
                    <Menu.Item key="transaction/midexchangerate">
                      <Link to={branch + "/transaction/midexchangerate"}><i className="icon icon-chart" />
                        <IntlMessages id="sidebar.transaction.midexchangerate" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.transaction_bank_account.akses &&
                    <Menu.Item key="transaction/bankaccount">
                      <Link to={branch + "/transaction/bankaccount"}><i className="icon icon-pricing-table" />
                        <IntlMessages id="sidebar.transaction.bankaccount" /></Link>
                    </Menu.Item>
                  }

                </MenuItemGroup>
              }

              {authUser.access.parent_master &&
                <MenuItemGroup key="master" className="gx-menu-group" title={<IntlMessages id="sidebar.master" />}>
                  {
                    authUser.access.master_bank.akses &&
                    <Menu.Item key="master/bank">
                      <Link to={branch + "/master/bank"}><i className="icon icon-home" />
                        <IntlMessages id="sidebar.master.bank" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.master_company_account.akses &&
                    <Menu.Item key="master/companyaccount">
                      <Link to={branch + "/master/companyaccount"}><i className="icon icon-home" />
                        <IntlMessages id="sidebar.master.company_account" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_work.akses &&
                    <Menu.Item key="master/usertype">
                      <Link to={branch + "/master/usertype"}><i className="icon icon-auth-screen" />
                        <IntlMessages id="sidebar.master.usertype" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_nationality.akses &&
                    <Menu.Item key="master/nationality">
                      <Link to={branch + "/master/nationality"}><i className="icon icon-map-google" />
                        <IntlMessages id="sidebar.master.nationality" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_currency.akses &&
                    <Menu.Item key="master/currency">
                      <Link to={branch + "/master/currency"}><i className="icon icon-litcoin" />
                        <IntlMessages id="sidebar.master.currency" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_fraction.akses &&
                    <Menu.Item key="master/currencyfrac">
                      <Link to={branch + "/master/currencyfrac"}><i className="icon icon-bitcoin" />
                        <IntlMessages id="sidebar.master.currencyfrac" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_currency_formula.akses &&
                    <Menu.Item key="master/currencyformula">
                      <Link to={branch + "/master/currencyformula"}><i className="icon icon-litcoin" />
                        <IntlMessages id="sidebar.master.currencyformula" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_user.akses &&
                    <Menu.Item key="master/user">
                      <Link to={branch + "/master/user"}><i className="icon icon-user-o" />
                        <IntlMessages id="sidebar.master.user" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_otp.akses &&
                    <Menu.Item key="master/otpuser">
                      <Link to={branch + "/master/otpuser"}><i className="icon icon-lock-screen" />
                        <IntlMessages id="sidebar.master.otpuser" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_registration.akses &&
                    <Menu.Item key="master/register">
                      <Link to={branch + "/master/register"}><i className="icon icon-user-o" />
                        <IntlMessages id="sidebar.master.register" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.master_customer.akses &&
                    <Menu.Item key="master/cust">
                      <Link to={branch + "/master/cust"}><i className="icon icon-user" />
                        <IntlMessages id="sidebar.master.cust" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.master_dttot.akses &&
                    <Menu.Item key="master/dttot">
                      <Link to={branch + "/master/dttot"}><i className="icon icon-exclamation" />
                      DTTOT</Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.master_proliferasi.akses &&
                    <Menu.Item key="master/proliferasi">
                      <Link to={branch + "/master/proliferasi"}><i className="icon icon-exclamation" />
                      Proliferasi WMD</Link>
                    </Menu.Item>
                  }

                </MenuItemGroup>
              }

              {authUser.access.parent_setting &&
                <MenuItemGroup key="utility" className="gx-menu-group" title={<IntlMessages id="sidebar.utility" />}>

                  {
                    authUser.access.setting_limit.akses &&
                    <Menu.Item key="utiliy/limit">
                      <Link to={branch + "/utility/limit"}><i className="icon icon-setting" />
                        <IntlMessages id="sidebar.utility.limit" /></Link>
                    </Menu.Item>
                  }



                </MenuItemGroup>
              }

              {authUser.access.parent_report &&
                <MenuItemGroup key="report" className="gx-menu-group" title={<IntlMessages id="sidebar.report" />}>

                  {
                    authUser.access.report_work.akses &&
                    <Menu.Item key="report/occupation">
                      <Link to={branch + "/report/occupation"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.occupation" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.report_nationality.akses &&
                    <Menu.Item key="report/nationality">
                      <Link to={branch + "/report/nationality"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.nationality" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.report_stock.akses &&
                    <Menu.Item key="report/stock">
                      <Link to={branch + "/report/stock"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.stock" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.report_stock_card.akses &&
                    <Menu.Item key="report/stockcard">
                      <Link to={branch + "/report/stockcard"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.stockcard" /></Link>
                    </Menu.Item>
                  }

                  {
                    authUser.access.report_business_activity.akses &&
                    <Menu.Item key="report/pva">
                      <Link to={branch + "/report/pva"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.pva" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_customer.akses &&
                    <Menu.Item key="report/cust">
                      <Link to={branch + "/report/cust"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.cust" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_labarugi.akses &&
                    <Menu.Item key="report/labarugi">
                      <Link to={branch + "/report/labarugi"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.labarugi" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_net.akses &&
                    <Menu.Item key="report/net">
                      <Link to={branch + "/report/net"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.net" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_ltkt.akses &&
                    <Menu.Item key="report/ltkt">
                      <Link to={branch + "/report/ltkt"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.ltkt" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_ltkm.akses &&
                    <Menu.Item key="report/ltkm">
                      <Link to={branch + "/report/ltkm"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.ltkm" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_service_product.akses &&
                    <Menu.Item key="report/productservice">
                      <Link to={branch + "/report/productservice"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.productservice" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_risk.akses &&
                    <Menu.Item key="report/risk">
                      <Link to={branch + "/report/risk"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.risk" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_deleted_trans_buy.akses &&
                    <Menu.Item key="report/deletedtransbuy">
                      <Link to={branch + "/report/deletedtransbuy"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.deletedtransbuy" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_deleted_trans_sell.akses &&
                    <Menu.Item key="report/deletedtranssell">
                      <Link to={branch + "/report/deletedtranssell"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.deletedtranssell" /></Link>
                    </Menu.Item>
                  }
                  {
                    authUser.access.report_customer_trans.akses &&
                    <Menu.Item key="report/customertrans">
                      <Link to={branch + "/report/customertrans"}><i className="icon icon-chart-bar" />
                        <IntlMessages id="sidebar.report.customertrans" /></Link>
                    </Menu.Item>
                  }


                </MenuItemGroup>
              }
            </Menu>
          }
        </CustomScrollbars>
      </div>
    </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser, dash } = auth;
  return { navStyle, themeType, locale, pathname, authUser, dash }
};
export default connect(mapStateToProps)(SidebarContent);

