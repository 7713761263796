import React from "react";
import {
  Button, Result, Form, Input, message,
  Typography, Select, DatePicker, Steps, AutoComplete
} from "antd";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { userSignUp } from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import InputMask from "react-input-mask";
import axios from 'util/Api'

const FormItem = Form.Item;

const { Title } = Typography;
const { Option } = Select;
const { Step } = Steps;
const AutoCompleteOption = AutoComplete.Option;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'main',
      current: 0,
      branchs: [],
      branchName: "",
      reg: {
        email: '',
        pwd: "",
        confirmpwd: "",
        mobile: "",
        firstname: "",
        lastname: "",
        gender: "",
        branch: "",
        birthdate: null,
        birthplace: "",
        address: ""
      },
      error: {
        email: '',
        pwd: "",
        confirmpwd: "",
        mobile: "",
        firstname: "",
        lastname: "",
        gender: "",
        branch: "",
        birthdate: "",
        birthplace: "",
        address: ""
      },
      loading: false
    }
  }

  handleChangeEmail(val) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(val.target.value.toLowerCase())) {
      this.setState({
        reg: {
          ...this.state.reg,
          email: val.target.value
        },
        error: {
          ...this.state.error,
          email: ""
        }
      })
    } else {
      this.setState({
        reg: {
          ...this.state.reg,
          email: val.target.value
        },
        error: {
          ...this.state.error,
          email: "Invalid Email"
        }
      })
    }
  }

  handlePwd(val) {
    if (val.target.value != this.state.reg.confirmpwd) {
      this.setState({
        reg: {
          ...this.state.reg,
          pwd: val.target.value
        },
        error: {
          ...this.state.error,
          confirmpwd: "Password tidak sama dengan konfirmasi password"
        }
      })
    } else {
      this.setState({
        reg: {
          ...this.state.reg,
          pwd: val.target.value
        },
        error: {
          ...this.state.error,
          confirmpwd: ""
        }
      })
    }
  }

  handleConfPwd(val) {
    if (val.target.value != this.state.reg.pwd) {
      this.setState({
        reg: {
          ...this.state.reg,
          confirmpwd: val.target.value
        },
        error: {
          ...this.state.error,
          confirmpwd: "Password tidak sama dengan konfirmasi password"
        }
      })
    } else {
      this.setState({
        reg: {
          ...this.state.reg,
          confirmpwd: val.target.value
        },
        error: {
          ...this.state.error,
          confirmpwd: ""
        }
      })
    }
  }

  checkValidateMain = () => {
    const { reg } = this.state;

    if (reg.email == '') {
      this.setState({
        error: {
          ...this.state.error,
          email: "Email tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          email: ""
        }
      })
    };

    if (reg.pwd == '') {
      this.setState({
        error: {
          ...this.state.error,
          pwd: "Password tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          pwd: ""
        }
      })
    }

    if (reg.confirmpwd == '') {
      this.setState({
        error: {
          ...this.state.error,
          confirmpwd: "Konfirmasi password tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          confirmpwd: ""
        }
      })
    };
    this.setState({
      step: 'second'
    })
  }



  handleSubmit = () => {
    this.checkValidateForm();
  };

  checkValidateForm() {
    const { reg } = this.state;
    if (reg.mobile == '') {
      this.setState({
        error: {
          ...this.state.error,
          mobile: "Mobile tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          mobile: ""
        }
      })
    };

    if (reg.firstname == '') {
      this.setState({
        error: {
          ...this.state.error,
          firstname: "Nawa lengkap tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          firstname: ""
        }
      })
    }
    // if (reg.lastname == '') {
    //   this.setState({
    //     error: {
    //       ...this.state.error,
    //       lastname: "Nama akhir tidak boleh kosong"
    //     }
    //   })
    // } else {
    //   this.setState({
    //     error: {
    //       ...this.state.error,
    //       lastname: ""
    //     }
    //   })
    // };

    if (reg.birthdate == null) {
      this.setState({
        error: {
          ...this.state.error,
          birthdate: "Tanggal lahir tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          birthdate: ""
        }
      })
    };

    if (reg.branch == '') {
      this.setState({
        error: {
          ...this.state.error,
          branch: "Cabang tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          branch: ""
        }
      })
    };

    if (reg.birthplace == '') {
      this.setState({
        error: {
          ...this.state.error,
          birthplace: "Tempat lahir tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          birthplace: ""
        }
      })
    };

    if (reg.address == '') {
      this.setState({
        error: {
          ...this.state.error,
          address: "Alamat tidak boleh kosong"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          address: ""
        }
      })
    };
    this.submitReg(reg);
  }

  submitReg(register) {
    this.setState({
      loading: true
    });
    let outer = this;
    axios.post('bo/registerint', register).then(({ data }) => {
      // console.log("data add", data)
      if (data.sta && data.stacod === 200) {
        message.success('Submit data success');
        outer.setState({
          step: 'info'
        });
        // outer.props.history.push('/signin')
      } else {
        message.error(data.msg);
        outer.setState({
          loading: false
        })
      }
    }).catch(function (error) {
      outer.setState({
        loading: false
      });
      message.error('Submit data failed:' + error.message);
    });
  }

  handleBranchChange = (value) => {
    axios.get('bo/branch/list', {
      params: {
        results: 10,
        search: value != undefined ? value : ''
      }
    })
      .then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          this.setState({
            branchs: data.data.list
          })
          // console.log("data roles", data.data)
        } else {
          message.error(data.msg);
        }
      }).catch(function (error) {
        message.error("Get data failed:" + error.message);
      })

  }

  getSelectBranch(val, option) {
    this.setState({
      branchName: val,
      reg: {
        ...this.state.reg,
        branch: option.key
      }
    })
  }

  componentDidUpdate() {
    // if (this.props.token !== null) {
    //   this.props.history.push('/');
    // }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        md: { span: 16 },
        lg: { span: 12 }
      },
    };
    const { reg, error } = this.state;
    const branchOptions = this.state.branchs.map((branch, i) => (
      <AutoCompleteOption key={branch.id} value={branch.name}>{branch.name}</AutoCompleteOption>
    ));
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            {
              this.state.step != "info" ?
                <div style={{
                  width: '100%',
                  paddingTop: 20,
                  paddingBottom: 20,
                  paddingRight: 35,
                  paddingLeft: 35
                }}>
                  <Title style={{ textAlign: 'center' }} level={3}>Pendaftaran User</Title>

                  {
                    this.state.step == "main" ?
                      <div>
                        <div>
                          <label>Email / Username <span>*</span></label>
                          <Input
                            type="email"
                            value={reg.email}
                            onChange={(val) => this.handleChangeEmail(val)}
                          />
                        </div>
                        {
                          error.email != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.email}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Password <span>*</span></label>
                          <Input
                            type="password"
                            value={reg.pwd}
                            onChange={(val) => this.handlePwd(val)}
                          />
                        </div>
                        {
                          error.pwd != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.pwd}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Konfirmasi Password <span>*</span></label>
                          <Input
                            type="password"
                            value={reg.confirmpwd}
                            onChange={(val) => this.handleConfPwd(val)}
                          />
                        </div>
                        {
                          error.confirmpwd != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.confirmpwd}</span>
                          </div>
                        }
                      </div> :
                      <div>
                        <div>
                          <label>No. HP <span>*</span></label>
                          <InputMask mask="9999 9999 9999 9999"
                            name="id number"
                            maskPlaceholder={""}
                            style={{
                              paddingLeft: 8,
                              height: 35,
                              width: '100%',
                              borderRadius: '5%'
                            }}
                            value={reg.mobile}
                            onChange={(val) => this.setState({
                              reg: {
                                ...this.state.reg,
                                mobile: val.target.value
                              }
                            })}
                          />
                        </div>
                        {
                          error.mobile != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.mobile}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Nama Lengkap <span>*</span></label>
                          <Input
                            value={reg.firstname}
                            onChange={(val) => this.setState({
                              reg: {
                                ...this.state.reg,
                                firstname: val.target.value
                              }
                            })}
                          />
                        </div>
                        {
                          error.firstname != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.firstname}</span>
                          </div>
                        }
                        <br />
                        {/* <div>
                          <label>Nama Akhir <span>*</span></label>
                          <Input
                            value={reg.lastname}
                            onChange={(val) => this.setState({
                              reg: {
                                ...this.state.reg,
                                lastname: val.target.value
                              }
                            })}
                          />
                        </div>
                        {
                          error.lastname != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.lastname}</span>
                          </div>
                        }
                        <br /> */}
                        <div>
                          <label>Jenis Kelamin <span>*</span></label>
                          <br />
                          <Select value={reg.gender} style={{ width: '100%' }} onChange={(val) => {
                            this.setState({
                              reg: {
                                ...this.state.reg,
                                gender: val
                              }
                            })
                          }}>
                            <Option value="Male">Pria</Option>
                            <Option value="Female">Wanita</Option>
                          </Select>
                        </div>
                        {
                          error.gender != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.gender}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Cabang <span>*</span></label>
                          <br />
                          <AutoComplete
                            dataSource={branchOptions}
                            onChange={this.handleBranchChange}
                            onFocus={this.handleBranchChange}
                            onSelect={(val, option) => this.getSelectBranch(val, option)}
                            filterOption={(inputValue, option) =>
                              option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            placeholder="Cabang"
                          >
                            <Input />
                          </AutoComplete>
                        </div>
                        {
                          error.branch != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.branch}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Tanggal Lahir <span>*</span></label>
                          <br />
                          <DatePicker
                            style={{ width: '100%' }}
                            format={'DD-MM-YYYY'}
                            value={reg.birthdate}
                            onChange={(val) => {
                              this.setState({
                                reg: {
                                  ...this.state.reg,
                                  birthdate: val
                                }
                              })
                            }}
                          />
                        </div>
                        {
                          error.birthdate != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.birthdate}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Tempat Lahir <span>*</span></label>
                          <Input
                            value={reg.birthplace}
                            onChange={(val) => this.setState({
                              reg: {
                                ...this.state.reg,
                                birthplace: val.target.value
                              }
                            })}
                          />
                        </div>
                        {
                          error.birthplace != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.birthplace}</span>
                          </div>
                        }
                        <br />
                        <div>
                          <label>Alamat Tinggal <span>*</span></label>
                          <Input.TextArea
                            value={reg.address}
                            onChange={(val) => this.setState({
                              reg: {
                                ...this.state.reg,
                                address: val.target.value
                              }
                            })}
                          />
                        </div>
                        {
                          error.address != '' &&
                          <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
                            <span>{error.address}</span>
                          </div>
                        }
                      </div>
                  }
                  <br />
                  {
                    this.state.step == "main" ?
                      <div>
                        <Button
                          onClick={() => this.checkValidateMain()}
                          type="dashed"
                          disabled={
                            reg.email == '' ||
                            reg.pwd == '' ||
                            reg.confirmpwd == '' ||
                            error.email != '' ||
                            error.pwd != '' ||
                            error.confirmpwd != ''
                          }
                          style={{ marginBottom: 0 }}
                        >
                          Next
                    </Button>
                        <span>
                          <IntlMessages id="app.userAuth.or" />
                          <span>
                            &nbsp;
                          <Link to="/signin">
                              <IntlMessages id="app.userAuth.signIn" />
                            </Link>
                          </span>
                        </span>
                      </div>
                      :
                      <div>

                        <Button onClick={() => this.setState({
                          step: 'main'
                        })} type="dashed">
                          Back
                    </Button>
                        <Button
                          loading={this.state.loading}
                          disabled={
                            reg.mobile == '' ||
                            reg.firstname == '' ||
                            reg.birthdate == null ||
                            reg.birthplace == '' ||
                            reg.address == '' ||
                            reg.branch == '' ||
                            error.mobile != '' ||
                            error.firstname != '' ||
                            error.birthdate != '' ||
                            error.birthplace != '' ||
                            error.address != '' ||
                            error.branch != ''
                          }
                          onClick={() => this.handleSubmit()}
                          type="primary"
                        >
                          Submit
                    </Button>
                      </div>
                  }
                </div> :
                <Result
                  status="success"
                  title="Pendaftaran User Sukses"
                  subTitle="Harap menunggu proses approvement oleh admin, Anda dapat melakukan login jika pendaftaran telah di approve. Status pendaftaran dapat di cek pada halaman cek pendaftaran."
                  extra={[
                    <Link to="/signcheck">
                      <Button key="check" type="primary">
                        Cek Pendaftaran
                    </Button>
                    </Link>,
                    <Link to="/signin">
                      <Button key="login">
                        Sign in
                    </Button>
                    </Link>
                  ]}
                />
            }
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token }
};

export default connect(mapStateToProps, { userSignUp })(WrappedSignUpForm);
