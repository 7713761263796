import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover, Divider, Icon} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import urlConfig from './../../util/urlGet';
import {Link, withRouter} from "react-router-dom";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  // toProf() {
  //   let history = useHistory();
  //   this.setState({
  //     visible: false
  //   }, () => {
  //     history.push("/profile");
  //   })
  // }

  render() {
    const {authUser, dash, pathname} = this.props;
    let branch = '';
    if (dash && dash.dashadmin){
      let urlPath = pathname.split("/")
      if(urlPath.length > 1){
        branch = "/" + urlPath[1];
      }
    }
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/profile">
          <li onClick={() => this.setState({visible: false})}>
              My Profile
          </li>
        </Link>
        {(dash && dash.dashadmin) &&
          <Divider/>
        }
        {(dash && dash.dashadmin) &&
          <li onClick={() => window.location.replace('/admin/dashboard')}>
              Master Dashboard
          </li>
        }
        <Divider/>
        <li onClick={() => this.props.userSignOut()}>
          <Icon type="poweroff"/> Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" onClick={() => this.setState({visible: true})} visible={this.state.visible} placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={authUser.pict != '' ? urlConfig.urlProfile + authUser.pict : require("assets/images/logo_mini_siv.png")}
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

const mapStateToProps = ({settings,auth}) => {
  const {pathname} = settings;
  const {authUser, dash} = auth;
  return {authUser, dash, pathname}
};

export default connect(mapStateToProps, {userSignOut})(UserInfo);
