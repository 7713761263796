import React from "react";
import {Button,Form, Input, Row, Col, 
  Typography, Result, message} from "antd";
import {connect} from "react-redux";

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {Link} from "react-router-dom";
import axios from 'util/Api'

const FormItem = Form.Item;
const {Title} = Typography;

class SignCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check : {
        email: ''
      },
      error: {
        email: ''
      },
      step: 'main',
      resultStatus: '',
      resultSub: '',
      loading: false
    }
  }

  handleChangeEmail(val) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(regex.test(val.target.value.toLowerCase())) {
      this.setState({
        check: {
          ...this.state.check,
          email: val.target.value
        },
        error: {
          ...this.state.error,
          email: ""
        }
      });
    } else {
      this.setState({
        check: {
          ...this.state.check,
          email: val.target.value
        },
        error: {
          ...this.state.error,
          email: "Invalid Email"
        }
      })
    }
  };


  handleCheck() {
    this.setState({
      loading: true
    });
    let outer = this;
    axios.post('bo/registerint/confirm', this.state.check).then(({data}) => {
      if (data.sta && data.stacod === 200) {
        // message.success('Submit data success');
        let sub = '';
        let stat = '';
        if(data.data.status == "Reject") {
          sub = "Maaf, registrasi anda telah ditolak";
          stat = "error";
        }
        if(data.data.status == "Pending") {
          sub = "Mohon menunggu, Registrasi anda sedang proses pengecekan oleh administrator";
          stat = "warning"
        }
        if(data.data.status == "Verified") {
          sub = "Akun anda telah aktif, anda dapat login menggunakan email dan password Anda";
          stat = "success"
        }
        outer.setState({
          loading: false,
          step: 'info',
          resultStatus: stat,
          resultSub: sub
        });
        // outer.props.history.push('/signin')
      }else{
        outer.setState({
          loading: false,
          step: 'info',
          resultStatus: 'warning',
          resultSub: data.msg
        })
      }
    }).catch(function (error) {
      outer.setState({
        loading: false
      });
      message.error('Submit data failed:'+error.message);
    });
  }


  render() {
    const {check, error, step} = this.state;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            {
              step == "main" ?
              <div style={{width: '100%', 
                  paddingTop: 20, 
                  paddingBottom: 20, 
                  paddingRight: 35, 
                  paddingLeft: 35}}>
                <Title style={{textAlign: 'center'}} level={3}>Cek Registrasi</Title>
                <div> 
                    <div>
                      <label>Email / Username <span>*</span></label>
                      <Input
                        type="email"
                        value={check.email}
                        onChange={(val) => this.handleChangeEmail(val)}
                      />
                    </div>
                    {
                      error.email != '' &&
                      <div style={{fontSize: 12, color: 'red', paddingTop: 5}}>
                        <span>{error.email}</span>
                      </div>
                    }
                    <br/>
                    <div>
                      <Button 
                        type="primary"
                        loading={this.state.loading} 
                        onClick={() => this.handleCheck()}
                      >
                        Cek Registrasi
                      </Button>
                      <span>
                          <IntlMessages id="app.userAuth.or"/>
                          <span>
                            &nbsp;
                            <Link to="/signin">
                              <IntlMessages id="app.userAuth.signIn"/>
                            </Link>
                          </span>
                      </span>
                    </div>
                </div>
              </div> : 
              <Result
                  style={{width: '100%'}}
                  status={this.state.resultStatus}
                  title="Cek Registrasi"
                  subTitle={this.state.resultSub}
                  extra={
                    [
                      <Button type="primary" onClick={() => 
                        this.setState({
                          step: 'main'
                        })
                      }
                      >
                        Ok
                      </Button>
                    ]
                }
              />
            }
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignCheck);

const mapStateToProps = ({auth}) => {
  const {tkn} = auth;
  return {tkn}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
