import React from "react";
import {Button,Form, Input, Row, Col} from "antd";
import {connect} from "react-redux";

import {userSignIn, userSignInOtp, clearOtp} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {Link} from "react-router-dom";

const FormItem = Form.Item;

class SignIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // stat: props.otp == "false" || !props.otp ? 'login' : "otp",
      saveEmail: ''   
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          saveEmail: values.username
        });
        this.props.userSignIn(values);
      }
    });
  };

  handleSubmitOtp = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let otpdata = {
          username: this.state.saveEmail,
          otpcode: values.otp
        };
        this.props.userSignInOtp(otpdata)
      }
    });
  };

  componentDidUpdate() {
    if (this.props.tkn !== null) {
      if (this.props.dash && this.props.dash.dashboard && this.props.dash.dashadmin){
        this.props.history.push('/admin/dashboard');
      }else if (this.props.dash && this.props.dash.dashboard){
        this.props.history.push('/dashboard');
      }else{
        this.props.history.push('/welcome');
      }
    }
  
  }

  changeBack() {
    this.setState({
      saveEmail: ""
    });
    this.props.clearOtp();
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/* <img src="https://via.placeholder.com/272x395" alt='Neature'/> */}
              </div>
              {/* <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div> */}
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo_login_siv.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={
                  this.props.otp == "false" || !this.props.otp ?
                  this.handleSubmit : this.handleSubmitOtp
                } className="gx-signin-form gx-form-row0">
                {
                  this.props.otp == 'false' || !this.props.otp ?

                  <div>

                    <FormItem>
                      {getFieldDecorator('username', {
                        initialValue: "",
                        // initialValue: "superadmin@siv.com",
                        rules: [{
                          required: true, type: 'email', message: 'Invalid Email',
                        }],
                      })(
                        <Input placeholder="Email"/>
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('password', {
                        initialValue: "",
                        // initialValue: "siv123456",
                        rules: [{required: true, message: 'Masukkan Kata Sandi'}],
                      })(
                        <Input.Password type="password" placeholder="Password"/>
                      )}
                    </FormItem>
                  </div> : 
                  <div>
                    <FormItem>
                      {getFieldDecorator('otp', {
                        initialValue: "",
                        // initialValue: "superadmin@siv.com",
                        rules: [{
                          required: true, message: 'Masukkan Kode Otp',
                        }],
                      })(
                        <Input type="number" placeholder="Kode OTP"/>
                      )}
                    </FormItem>
                  </div>
                }
                <FormItem>
                    
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      {
                        (this.props.otp == "false" || !this.props.otp) ?
                        <IntlMessages id="app.userAuth.signIn"/> :
                        "Submit"
                      }
                    </Button>
                    
                </FormItem>
                <div className="gx-flex-row gx-justify-content-betweeen">
                  {(this.props.otp == 'false' || !this.props.otp) &&
                    <>
                      <span>
                        <Link to="/signup">
                          <IntlMessages id="app.userAuth.signUp"/>
                        </Link>
                      </span>
                      &nbsp;
                      |
                      &nbsp;
                      <span><Link to="/signcheck">Cek Registrasi</Link></span>
                    </>
                  }
                  {
                    (this.props.otp == "false" || !this.props.otp) ?
                    null :
                    <span onClick={() => this.changeBack()}>
                      <a>
                        Login With Other Account
                      </a>
                    </span>
                  }
                </div>
                <span
                  className="gx-text-light gx-fs-sm"></span>
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {tkn, otp, dash} = auth;
  return {tkn, otp, dash}
};

export default connect(mapStateToProps, {
  userSignIn, 
  userSignInOtp, 
  clearOtp
})(WrappedNormalLoginForm);
