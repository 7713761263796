import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import asyncComponent from "util/asyncComponent";

const App = ({ match, authUser, dash }) => {
  // console.log("auth user", authUser);
  return (
    (authUser && Object.entries(authUser).length > 0) &&
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}welcome`} component={asyncComponent(() => import('./Welcome'))} />
        <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/dashboard`} component={asyncComponent(() => import('./Dashboard'))} /> :
          <Route path={`${match.url}:branch?/dashboard`} component={asyncComponent(() => import('./Error'))} />
        }
        <Route path={`${match.url}master`} component={asyncComponent(() => import('./Master'))} />
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/master`} component={asyncComponent(() => import('./Master'))} /> :
          <Route path={`${match.url}:branch?/master`} component={asyncComponent(() => import('./Error'))} />
        }
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/transfer`} component={asyncComponent(() => import('./Transfer'))} /> :
          <Route path={`${match.url}:branch?/transfer`} component={asyncComponent(() => import('./Error'))} />
        }
        <Route path={`${match.url}transaction`} component={asyncComponent(() => import('./Transaction'))} />
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/transaction`} component={asyncComponent(() => import('./Transaction'))} /> :
          <Route path={`${match.url}:branch?/transaction`} component={asyncComponent(() => import('./Error'))} />
        }
        <Route path={`${match.url}utility`} component={asyncComponent(() => import('./Utility'))} />
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/utility`} component={asyncComponent(() => import('./Utility'))} /> :
          <Route path={`${match.url}:branch?/utility`} component={asyncComponent(() => import('./Error'))} />
        }
        <Route path={`${match.url}report`} component={asyncComponent(() => import('./Report'))} />
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/report`} component={asyncComponent(() => import('./Report'))} /> :
          <Route path={`${match.url}:branch?/report`} component={asyncComponent(() => import('./Error'))} />
        }
        <Route path={`${match.url}error`} component={asyncComponent(() => import('./Error'))} />
        <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))} />
        {dash.dashadmin ?
          <Route path={`${match.url}:branch?/profile`} component={asyncComponent(() => import('./Profile'))} /> :
          <Route path={`${match.url}:branch?/profile`} component={asyncComponent(() => import('./Error'))} />
        }
      </Switch>
    </div>
  )
};

const mapStateToProps = ({ auth }) => {
  const { authUser, dash } = auth;
  return { authUser, dash }
};

export default connect(mapStateToProps)(App);
