import React from 'react';
import CircularProgress from "components/CircularProgress/index";
import {message} from 'antd';
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import {hideMessage} from "appRedux/actions/Common";

class InfoView extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.error || nextProps.message || nextProps.displayMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
  }

  render() {
    const {error, loading, displayMessage} = this.props;
    const circularProgress = () => {
      if(loading){
        return (<div className="gx-loader-view">
          <CircularProgress/>
        </div>)
      }
      return (<div></div>);
    } 
    const SuccessMsg = () => {
      if(displayMessage){
       message.success(displayMessage)
      }
    }

    const ErrorMsg = () => {
      if(error){
        message.error(error)
      }
    }

    SuccessMsg();
    ErrorMsg();

    return (
      <Auxiliary>
        {circularProgress()}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({commonData}) => {
  const {error, loading} = commonData;
  const displayMessage = commonData.message;
  return {error, loading, displayMessage};
};

export default connect(mapStateToProps, {hideMessage})(InfoView);
