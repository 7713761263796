import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  SHOW_MESSAGE
} from "../../constants/ActionTypes";
import axios from 'util/Api';
import { message } from 'antd';

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  // console.log("check user sign up", email, password);
  // return (dispatch) => {
  //   dispatch({type: FETCH_START});
  //   axios.post('bo/registerint', {
  //       email: email,
  //       password: password,
  //       name: name
  //     }
  //   ).then(({data}) => {
  //     // console.log("data:", data);
  //     if (data.result) {
  //       // axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
  //       // localStorage.setItem("token", JSON.stringify(data.token.access_token));
  //       dispatch({type: FETCH_SUCCESS, payload:'Success'});
  //       // dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
  //       // dispatch({type: USER_DATA, payload: data.user});
  //     } else {
  //       dispatch({type: FETCH_ERROR, payload: "Network Error"});
  //     }
  //   }).catch(function (error) {
  //     dispatch({type: FETCH_ERROR, payload: error.message});
  //     console.error("Error****:", error.message);
  //   });
  // }
};

export const userSignIn = ({username, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    
    //bypass axios
    // localStorage.setItem("token", JSON.stringify('login'));
    // dispatch({type: FETCH_SUCCESS});
    // dispatch({type: USER_TOKEN_SET, payload: 'login'});
    axios.post('bo/login', {
        email: username,
        pwd: password,
      }
    ).then(({data}) => {
      // userSignUp(username, password);
      if (data.sta && data.stacod === 200) {
        if(data.data) {
          localStorage.setItem("otp", "false");
          localStorage.setItem("tkn", data.data.tkn);
          localStorage.setItem("tknref", data.data.tknref);
          localStorage.setItem("dash", JSON.stringify({dashboard:data.data.dashboard,dashadmin:data.data.dashadmin}));
          axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.tkn;
          dispatch({type: FETCH_SUCCESS, payload:'Success'});
          dispatch({type: USER_TOKEN_SET, payload: {tkn:data.data.tkn,tkref:data.data.tknref, otp: "false", dash: {dashboard:data.data.dashboard,dashadmin:data.data.dashadmin}}});
        } else {
          localStorage.setItem("otp", "true");
          dispatch({type: FETCH_SUCCESS, payload:'Success'});
          dispatch({type: USER_TOKEN_SET, payload: {tkn:null,tkref:null, otp: "true", dash: null}});
        }
      } else {
        dispatch({type: FETCH_ERROR,payload:data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
    
  }
};

export const userSignInOtp = ({username, otpcode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    
    //bypass axios
    // localStorage.setItem("token", JSON.stringify('login'));
    // dispatch({type: FETCH_SUCCESS});
    // dispatch({type: USER_TOKEN_SET, payload: 'login'});
    axios.post('bo/login/otp', {
        email: username,
        otpcode: otpcode,
      }
    ).then(({data}) => {
      // userSignUp(username, password);
      if (data.sta && data.stacod === 200) {
          localStorage.setItem("otp", "false");
          localStorage.setItem("tkn", data.data.tkn);
          localStorage.setItem("tknref", data.data.tknref);
          localStorage.setItem("dash", JSON.stringify({dashboard:data.data.dashboard,dashadmin:data.data.dashadmin}));
          axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.tkn;
          dispatch({type: FETCH_SUCCESS, payload:'Success'});
          dispatch({type: USER_TOKEN_SET, payload: {tkn:data.data.tkn,tkref:data.data.tknref, otp: "false", dash: {dashboard:data.data.dashboard,dashadmin:data.data.dashadmin}}});
        
      } else {
        dispatch({type: FETCH_ERROR,payload:data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
    
  }
};

export const clearOtp = () => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    localStorage.setItem("otp", "false");
    dispatch({type: USER_TOKEN_SET, payload: {tkn:null,tkref:null, otp: "false"}});
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.interceptors.response.use(res => {
      return res;
      // Important: response interceptors **must** return the response.
    }, err => {
      if (err.response.status === 401) {
        message.error("Sesi anda telah berakhir, silahkan login kembali.")
        setTimeout(() => {
          axios.defaults.headers.common['Authorization'] = null;
          dispatch({type: USER_DATA, payload: ''});
          localStorage.removeItem("tkn");
          localStorage.removeItem("tknref");
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: USER_DATA, payload: {}});
          dispatch({type: SIGNOUT_USER_SUCCESS});
        }, 2000);
      }
      // console.log("intercept error ", err.response.status);
    });
    axios.post('bo/user/auth',{}
    ).then(({data}) => {
      // console.log("userSignIn: ", data);
      if (data.sta && data.stacod === 200) {
        // dispatch({type: FETCH_SUCCESS, payload: data.data});
        let accesslist = {};
        let dashboard = 0;
        let master = 0;
        let transaction = 0;
        let setting = 0;
        let report = 0;
        let transaction_list = 0;
        data.data.accesslist.forEach(access => {
          if(access.akses == 'true'){
            let splitid = access.id.split("_");
            switch (splitid[0]) {
              case "master":
                master++;
                break;
              case "dashboard":
                dashboard++;
                break;
              case "transaction":
                transaction++;
                break;
              case "setting":
                setting++;
                break;
              case "report":
                report++;
                break;
            
              default:
                break;
            }
            if(access.id == 'transaction_buy_list' || access.id == 'transaction_sell_list'){
              transaction_list++;
            }
          }
          accesslist[access.id] = {};
          accesslist[access.id]["name"] = access.name;
          accesslist[access.id]["akses"] = access.akses == "true" ? true : false;
          accesslist[access.id].insert = access.insert == "true" ? true : false;
          accesslist[access.id].update = access.update == "true" ? true : false;
          accesslist[access.id].delete = access.delete == "true" ? true : false;
          accesslist[access.id].special = access.special == "true" ? true : false;
        });
        if(dashboard > 0){
          accesslist["parent_dashboard"] = true;
        }else{
          accesslist["parent_dashboard"] = false;
        }
        if(master > 0){
          accesslist["parent_master"] = true;
        }else{
          accesslist["parent_master"] = false;
        }
        if(transaction > 0){
          accesslist["parent_transaction"] = true;
        }else{
          accesslist["parent_transaction"] = false;
        }
        if(setting > 0){
          accesslist["parent_setting"] = true;
        }else{
          accesslist["parent_setting"] = false;
        }
        if(report > 0){
          accesslist["parent_report"] = true;
        }else{
          accesslist["parent_report"] = false;
        }
        if(transaction_list > 0){
          accesslist["parent_transaction_list"] = true;
        }else{
          accesslist["parent_transaction_list"] = false;
        }
        let user = {
          name: data.data.name,
          email: data.data.email,
          pict: data.data.pict,
          role: data.data.role,
          branch: data.data.branch,
          access: accesslist
        }
        // console.log("user ", user);
        dispatch({type: USER_DATA, payload: user});
      } else {
        // console.log('getUser NOT Okee')
        // dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.error("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {
  // axios.defaults.headers.common['Authorization'] = null;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      axios.defaults.headers.common['Authorization'] = null;
      dispatch({type: USER_DATA, payload: ''});
      localStorage.removeItem("tkn");
      localStorage.removeItem("tknref");
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: USER_DATA, payload: {}});
      dispatch({type: SIGNOUT_USER_SUCCESS});
    }, 2000);
  }
};
